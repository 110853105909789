
.courses-search-form-wrapper{
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &.without-title{
        justify-content: end;
    }

    @media (max-width: 767px) {
        display: block;
    }
    h1{
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-right: 15px;

        @media (max-width: 767px){
            text-align: center;
            margin-right: 0;
            font-size: 26px;
        }
    }

    &.justify-content-center{
        justify-content: center;
    }
}
.courses-search-form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 42px;
    height: 42px;
    overflow: hidden;
    transition: 0.2s;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    flex: 0 0 42px;
    margin-bottom: 10px;

    @media (max-width: 767px){
        margin-left: auto;
        margin-bottom: 0;
    }
}
.courses-search-form.show {
    width: 300px;
    flex: 0 0 300px;
    border-color: #c2c2c2;

    @media (max-width: 767px){
        flex: 0 0 auto;
        width: 100%;
    }
}
.courses-search-form button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 50%;
    padding: 5px;

    svg{
        width: 18px;
    }
}
.courses-search-form.show button {
    display: none;
}
.courses-search-form input {
    position: absolute;
    right: 0;
    height: 40px;
    width: 100%;
    z-index: 1;
    pointer-events: auto;
    user-select: auto;
    background-color: #fff;
    border: none;
    padding-left: 37px;
    padding-right: 30px;
}
.courses-search-form input:focus {
    outline: none;
}

.input-wrapper {
    position: relative;
    width: 0;
    height: 40px;
}
.input-wrapper svg {
    position: absolute;
    left: 5px;
    width: 25px;
    top: 6px;
    z-index: 3;
    display: none;
}
.courses-search-form.show .input-wrapper {
    width: 100%;
    display: block;
}
.courses-search-form.show .input-wrapper svg {
    display: block;
}

.courses-container{
    max-width: 1076px;
    margin: 0 auto;
    padding: 5vh 20px 100px;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 60px);

    @media (min-width: 1500px) {
        max-width: 1428px;
    }
    @media (max-width: 1199px) {
        max-width: 724px;
    }
    @media (max-width: 767px){
        max-width: 372px;
    }
}
.new-course-container{
    max-width: 1350px;
    margin: 0 auto;
    padding: 5vh 20px 100px;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 60px);

    @media (max-width: 1199px) {
        max-width: 992px;
    }
    @media (max-width: 991px) {
        max-width: 724px;
    }
    @media (max-width: 767px) {
        max-width: 372px;
    }
}

.categories-wrapper{
    gap: 10px;

    a{
        padding: 4px 10px;
        border-radius: 4px;
        background-color: #f4f4f4;
        color: #757575;
        font-size: 14px;
        letter-spacing: 0.02em;

        @media (max-width: 767px){
            letter-spacing: 0;
            padding: 4px 8px;
        }
        &:hover{
            background-color: #eaeaea;
            color: #595959;
            text-decoration: none;
        }
        &.active{
            color: #ffffff;
            text-decoration: none;
            background-color: #9e9e9e;
        }
    }
}

.courses-content{
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .no-results{
        grid-column: span 3;
    }
}
.new-courses-content{
    padding-left: 24px;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 991px) {
        padding-left: 0;
    }
}

.course-item {
    width: 100%;
    color: #000000;
    border: 1px solid #eaebf4;
    display: block;
    border-radius: 10px;
    position: relative;
    transition: all .3s;
    overflow: hidden;
    flex: 0 0 calc(33.333% - 13.34px);

    &:not(.new-courses-content .course-item){
        @media (min-width: 1500px) {
            flex: 0 0 calc(25% - 15px);
        }
    }
    @media (max-width: 1199px){
        flex: 0 0 calc(50% - 10px);
    }
    @media (max-width: 767px){
        flex: 0 0 100%;
    }

    &:hover{
        color: #000000;
        text-decoration: none;

        .course-info-block, .star-block{
            background-color: #f6f6f6;
        }
    }
    .star-block{
        justify-content: flex-end;
        border-bottom: solid 1px #ebebeb;
    }
}
.course-item-img-block{
    height: 186px;
    width: 100%;
    overflow: hidden;
}
.course-item-img{
    height: 186px;
    width: 100%;
    object-fit: cover;
}
.course-info-block{
    background-color: #ffffff;
    padding: 10px 20px;
    height: 230px;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
        height: inherit;
    }
    h2{
        font-size: 18px;
        font-weight: 600;
        line-height: 1.389;
        margin-bottom: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    > p{
        font-size: 14px;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 12px;
    }
}
.course-author{
    padding: 0 0 12px;
    display: flex;
    align-items: center;

    img{
        width: 40px;
        height: 40px;
        object-fit: cover;
        margin-right: 6px;
        border-radius: 50%;
    }
    span{
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
    }
}
.course-price{
    padding: 10px 0;
    line-height: 1.2;
    margin-top: auto;

    p{
        font-size: 14px;
        color: #444960;
        padding-right: 10px;

        @media (max-width: 358px){
            font-size: 12px;
        }
    }
    span{
        font-size: 16px;
        font-weight: 600;

        @media (max-width: 358px){
            font-size: 16px;
        }
    }
    del{
        font-size: 14px;
        opacity: .6;
        margin-right: 4px;
        display: inline-block;

        @media (max-width: 358px){
            margin-right: 7px;
            margin-bottom: 0;
        }
    }
}


.course-single-head{
    background-color: #F4F5F8;
    display: flex;
    align-items: center;

    @media (max-width: 991px){
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 50px;
    }
    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }
}
.course-single-head-left{
    flex-grow: 1;
    padding: 27px 20px 27px 6.5vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 991px){
        max-width: 760px;
        padding: 0 20px 30px 20px;
    }
    @media (max-width: 767px){
        padding: 20px 20px 0 20px;
    }

    h1{
        font-size: 26px;
        font-weight: 600;
        line-height: 1.35;
        margin-bottom: 0;

        @media (max-width: 1199px){
            font-size: 22px;
        }
        @media (max-width: 767px){
            font-size: 18px;
        }
    }
    p{
        color: #444960;
    }
    .star-block{
        background-color: transparent;
        padding: 0;
        margin-bottom: 0;
        justify-content: flex-start;
        border: none;

        .Stars{
            margin: 0;
        }
    }
}
.course-single-head-left-text{
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 767px) {
        flex-wrap: wrap;
    }
}
.course-single-price{
    font-size: 26px;
    font-weight: 600;

    @media (max-width: 1199px){
        font-size: 24px;
    }
    del{
        color: #444D68;
        font-size: 17px;
        margin-left: 10px;
    }
}
.course-single-head-right{
    max-width: 990px;
    flex: 0 0 56%;
    text-align: center;

    @media (max-width: 991px){
        padding: 0 20px;
        max-width: 760px;
    }
    @media (max-width: 767px){
        flex: 0 0 100%;
    }

    img{
        max-width: 100%;
        object-fit: contain;
        max-height: 40vw;

        @media (max-width: 991px){
            max-height: none;
        }
    }
}
.course-single-container{
    max-width: 1056px;
    margin: 0 auto;
    padding: 60px 15px;

    @media (max-width: 767px){
        padding: 40px 15px;
    }
}

.font-20{
    font-size: 20px;
}
.course-single-video-container{
    margin-top: 70px;

    @media (max-width: 767px){
        margin-top: 40px;
    }
    .classes-accordion{
        padding: 0;
    }
}
.btn-free{
    padding: 6px 15px;
    border: solid 1px;
    font-size: 14px;
    background-color: var(--btn1-outline-background-color);
    color: var(--btn1-outline-text-color);
    border-color: var(--btn1-outline-border-color);

    &:hover{
        background-color: var(--btn1-outline-background-hover-color);
        border-color: var(--btn1-outline-background-hover-color);
        color: var(--btn1-outline-text-color-hover);
    }
}
.classes-accordion {

    > .card .card-header {
        margin-bottom: 3px;
    }

    .card-body {
        padding: 10px 16px 10px 43px;
        border: 1px solid #F1F1F3;
        margin-bottom: 3px;
        font-size: 18px;
        color: #000000;
        border-radius: 3px;
        position: relative;
        display: flex;
        align-items: center;

        @media (max-width: 991px){
            font-size: 16px;
        }
        @media (max-width: 575px) {
            font-size: 14px;
            padding: 8px 10px 8px 12px;
        }
        > img{
            margin-right: 15px;
            width: 21px;
            height: 23px;
            flex: 0 0 21px;

            @media (max-width: 575px){
                margin-right: 10px;
                width: 17px;
                height: 18px;
                flex: 0 0 17px;
            }
        }
        .btn{
            width: 117px;
            flex: 0 0 117px;
            font-size: 14px;
            padding: 7px 15px;

            @media (max-width: 575px){
                padding: 2px 7px;
                width: 100px;
                flex: 0 0 100px;
                white-space: nowrap;
            }
        }
        .episode-time{
            font-size: 15px;
            margin-right: 13px;
            color: #000000;
            text-align: left;
            width: 124px;
            flex: 0 0 124px;

            @media (max-width: 767px){
                display: none;
            }
        }
        .episode-title{
            white-space: normal;
            flex-grow: 1;
            font-size: 16px;
            padding-right: 15px;
            width: auto;

            @media (max-width: 575px){
                flex: 1 1 auto;
                padding-right: 12px;
                font-size: 14px;
            }
        }
    }
    .card-header {
        background-color: transparent;

        .btn {
            text-transform: inherit;
            border: 1px solid #F1F1F3;
            border-radius: 3px;
            padding: 0.8rem 1.2rem 0.8rem 43px;
            background-color: #F4F5F8;
            text-align: left;
            font-size: 18px;
            letter-spacing: 0;
            color: #292929;
            align-items: center;

            &:focus {
                box-shadow: none;
            }
            @media (max-width: 991px){
                font-size: 16px;
            }
            @media (max-width: 767px) {
                font-size: 14px;
                padding: 20px 10px 20px 30px;
            }

            &:after {
                content: "+";
                width: 21px;
                flex: 0 0 21px;
                height: 100%;
                display: inline-flex;
                position: absolute;
                left: 12px;
                top: 0;
                font-size: 36px;
                line-height: 1;
                text-align: center;
                align-items: center;
                font-family: sans-serif;
                justify-content: center;

                @media (max-width: 767px){
                    font-size: 26px;
                    left: 4px;
                }
            }

            &[aria-expanded="true"]:after {
                content: "-";
                padding-bottom: 3px;
            }

        }
    }
}

.episode-title{
    flex-grow: 1;
    margin-right: auto;
    line-height: 1.4;
    padding-right: 15px;

    @media (max-width: 575px){
        width: calc(100% - 80px);
        flex: 0 0 calc(100% - 80px);
        line-height: 1.2;
        padding-right: 10px;
    }
}
.episode-count{
    width: 124px;
    flex: 0 0 124px;
    margin-right: 10px;
    font-size: 16px;

    @media (max-width: 575px){
        margin-right: 0;
        font-size: 12px;
        margin-left: auto;
        position: absolute;
        right: 10px;
        top: 2px;
        width: auto;
    }
}
.episode-time{
    width: 117px;
    flex: 0 0 117px;
    text-align: right;
    font-size: 14px;

    @media (max-width: 575px){
        font-size: 10px;
        margin-left: auto;
        position: absolute;
        right: 10px;
        top: 21px;
        width: 82px;
    }
}

.test-certificate{
    margin-top: 55px;

    @media (max-width: 767px){
        margin-top: 40px;
    }
    > img{
        width: 45%;
        max-width: 390px;

        @media (max-width: 767px){
            width: 100%;
            max-width: inherit;
        }
    }
}
.test-certificate-info{
    width: 50%;
    max-width: 485px;
    padding-top: 3px;

    @media (max-width: 767px){
        width: 100%;
        order: -1;
        max-width: inherit;
        text-align: center;
        margin-bottom: 40px;
        padding-top: 0;
    }
    h2{
        font-size: 25px;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 26px;

        @media (max-width: 899px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
    p{
        margin-bottom: 6px;
    }
    h3{
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 46px;

        @media (max-width: 899px){
            font-size: 18px;
            margin-bottom: 35px;
        }
    }
}
.course-coach-section{
    background-color: #F4F5F8;
    padding: 60px 15px;

    @media (max-width: 767px){
        padding: 40px 15px;
    }
    .title-h3{
        margin-top: 0;
        margin-bottom: 43px;
    }
}
.course-coach-container{
    max-width: 1030px;
    margin: 0 auto;

    figure{
        width: 200px;
        margin-bottom: 0;
        text-align: center;

        @media (max-width: 991px){
            width: 220px;
        }
        @media (max-width: 575px) {
            width: 100%;
            margin-top: 16px;
        }

        img{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
            margin-bottom: 15px;
        }
        h4{
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 0;
        }
        h5{
            font-size: 16px;
            margin-bottom: 0;
            line-height: 1.6;
        }
    }
}
.course-coach-info{
    width: calc(100% - 200px);
    padding-left: 25px;
    font-size: 18px;
    letter-spacing: 0.54px;
    color: #292929;
    line-height: 1.4;

    @media (max-width: 991px){
        font-size: 16px;
        width: calc(100% - 220px);
        padding-left: 20px;
    }
    @media (max-width: 575px) {
        width: 100%;
        padding-left: 0;
        order: -1;
    }
    h3{
        font-weight: bold;
        font-size: 19px;
        margin-bottom: 16px;

        @media (max-width: 575px){
            text-align: center;
        }
    }
}


//video
:root {
    --color-text: #000000;
    --color-bg: #fff;
    --color-bg-btn: #f8f8f8;
    --color-bg-hover: #f2f2f2;
    --color-bg-content: #F5F5F5;
    --color-bg-page: #ffffff;
}

.dark-mode:root {
    --color-text: #e5e5e5;
    --color-bg: #000000;
    --color-bg-btn: #444347;
    --color-bg-hover: #5b5b5f;
    --color-bg-content: #2d2c31;
    --color-bg-page: #18171c;
}
body{
    background: var(--color-bg);
    color: var(--color-text);
}

.dark-mode{


    .video-info-block-title .btn svg{
        filter: contrast(0);
    }
    .openCloseToggle, .nextId, .previousId{
        background-color: var(--color-bg-hover);
    }
    .mob-course-content, .video-info-block .px-4{
        background-color: #4b4b4b;
    }
    .play-ul li{
        background-color: #1f1f1f;
    }
    .play-ul .active, .video-info-block-title, .accordion-button .btn[aria-expanded=true], .mob-course-content .progress-block {
        background-color: #000000;
    }
    .accordion-button{
        background-color: #282828;
    }
    .toggle-block{
        background-color: #000000;
    }
    .video-info-block{
        border-color: transparent;
    }
    .btn-back{
        color: #fff;
        background-color: #4b4b4b;

        svg path{
            fill: #fff;
        }
        &:hover{
            color: #ffffff;
        }
    }
    .toogle-span-dark{
        color: #ffffff;
    }
    .toogle-span{
        color: #929292;
    }
    #toggle-mode .toggle-border{
        border-color: white;
        background-color: #ffffff;
    }
    #toggle-mode .toggle-indicator{
        background-color: #1f2933;
        left: calc( 50px - 23px );
        transform: rotate(
                200deg);
        top: 3px;
        overflow: visible;
    }
    .episode-text, .episode-file-item{
        color: #ffffff;
    }
    .video-teacher-info::-webkit-scrollbar-thumb,
    .episode-text::-webkit-scrollbar-thumb{
        background-color: #8d8d8d;
    }
    .video-section .video-js{
        background: var(--color-bg-page);
    }
    .menu-arrow{
        border-left: 1px solid #ffffff;
        border-top: 1px solid #ffffff;
    }

    table{
        color: #fff;
    }
    .btn.btn-overview{
        background: #000000;
    }
    .on_processing_wrapper h5{
        color: black;
    }

    @media (max-width: 991px) {
        .mob-course-content .progress-block{
            background-color: #4b4b4b;
        }
    }
}

.btn-back {
    position: absolute;
    left: 17px;
    top: 10px;
    padding: 5px 5px;
    border-radius: 3px;
    color: #000;
    z-index: 1111;
    transform: rotate(0deg);
    width: auto;
    right: inherit;
    background-color: #fff;

    &:hover {
        text-decoration: none;
        color: black;
        text-shadow: 0 0;
    }
    @media (max-width: 991px){
        left: 15px;
    }
    @media (max-width: 600px) {
        top: 15px;

        span {
            display: none;
        }
    }
}
.toggle-block {
    position: absolute;
    top: 9px;
    right: 12px;
    padding: 10px;
    width: 360px;
    z-index: 9999;
    background-color: #ffffff;
    border-radius: 3px;

    label{
        margin: 0 8px;
    }
}
#toggle-mode .toggle-border {
    border: 1px solid #ffffff;
    width: 50px;
    height: 25px;
    display: block;
    border-radius: 20px;
    position: relative;
    background-color: #d1d1cf;
}
#toggle-mode .toggle-indicator {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    position: absolute;
    background-color: #ffffff;
    top: 2px;
    left: 2px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    box-sizing: border-box;
}
#toggle-mode .toggle-indicator::after {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    bottom: -3px;
    right: 5px;
    background-color: #ffffff;
}
.toogle-span{
    font-size: 13px;
}
.toogle-span-dark{
    color: #B7B7B7;
    font-size: 13px;
}

.position-btn {
    cursor: pointer;
    margin: 0 5px;
}


.episode-section{
    background: var(--color-bg-page);
    height: 100vh;
    min-height: 500px;
    width: calc(100% - 380px);
    transition: width .3s;
    overflow: auto;
    padding-bottom: 30px;

    @media (hover){

        &::-webkit-scrollbar{
            width: 0;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #c8c8c8;
            border-radius: 16px;
        }
    }
    @media (max-width: 991px){
        width: 100%;
        height: auto;
        min-height: inherit;
        display: flex;
        flex-direction: column;
        padding-bottom: 70px;
    }
}
.video-section {
    width: 100%;
    margin: auto;
    justify-content: space-between;
    height: 100%;
    padding: 60px 30px 20px 30px;
    align-items: flex-start;
    overflow: hidden;

    @media (max-width: 991px) {
        flex-direction: column;
        max-height: inherit;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 60px 15px 0;
        margin-bottom: 10px;
        height: auto;
    }

    .plyr__video-wrapper{
        background: var(--color-bg-page);
    }
    iframe{
        max-width: 100%;
    }

    @media (min-width: 992px) {
        &:has(.plyr){
            height: auto;
            min-height: 250px;
            width: auto;
            max-width: 100%;
        }
        &:has(.episode-description){
            margin: 0;
        }
        &:has(.on_processing_wrapper){
            width: 100%;
        }
    }

}
.video-js {
    height: 100%;
    width: 100%;
    margin: auto;
    font-size: 12px;

    @media (max-width: 991px) {
        width: 100%;
        height: auto;
    }
}
#youtube_video{
    width: 100%;
    height: 100%;
    background: var(--color-bg-page);

    @media (max-width: 991px) {
        width: 100%;
        height: 52vw;
        margin-bottom: 10px;
        padding: 0;
    }

    &:focus{
        outline: none;
    }
}
.episode-quizzes-iframe, .episode-form-iframe{
    width: 100%;
    height: 100%;

    @media (max-width: 991px){
        height: calc(100vh - 200px);
    }
}
.iframe-section{
    width: 100%;
    height: 100%;

    @media (max-width: 991px){
        margin: auto;
    }
    iframe{
        width: 100%;
        height: 100%;
    }
}
.mob-episode-title-block{
    padding: 0 35px 10px;
    margin-bottom: 10px;

    span{
        white-space: nowrap;

        &:first-child{
            opacity: .5;
        }
    }
}
.mob-episode-title{
    font-weight: 500;
    padding-left: 5px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.episode-description{
    padding: 15px 30px 15px;
    flex-grow: 1;
    overflow: auto;

    @media (min-width: 992px) {
        max-width: 800px;
        margin: 0 auto;
    }
    @media (max-width: 991px){
        padding: 15px 35px 15px;
    }

    //@media (hover) {
    //    &::-webkit-scrollbar-thumb {
    //        background-color: #c8c8c8;
    //        border-radius: 1px;
    //    }
    //    &::-webkit-scrollbar {
    //        width: 10px;
    //    }
    //}
}
.custom-video-description{
    font-size: 15px;
    padding-bottom: 20px;
    border-bottom: solid 1px #d6d6d6;
}
.episode-attachment-title{
    font-size: 22px;
    margin-bottom: 10px;

    @media (max-width: 991px){
        font-size: 20px;
    }
}
.episode-attachment-file{
    margin: 3px 0;
    display: inline-block;
}

.episode-text{
    background: var(--color-bg-content);
    padding: 20px 25px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 1199px) {
        padding: 4vw 2vw;
    }
    @media (max-width: 991px){
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 5px;
        width: 100%;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #282828;
        border-radius: 16px;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }

    h1{
        font-size: 30px;

        @media (max-width: 991px){
            font-size: 24px;
        }
    }
}

.episode-file-section{
    background: var(--color-bg-content);
    padding: 20px;
    border-radius: 3px;

    @media (max-width: 500px){
        padding: 10px;
    }
    > h1{
        font-size: 30px;
        margin-bottom: 20px;

        @media (max-width: 991px){
            font-size: 24px;
        }
    }
}
.episode-file{
    width: 100%;
    background-color: var(--color-bg);
    margin-bottom: 10px;

    @media (max-width: 500px){
        margin-bottom: 5px;
    }
}
.episode-file-item{
    padding: 10px 25px;

    @media (max-width: 991px){
        padding: 5px 10px;
    }
    .episode-file-item-left img{
        width: 52px;
        height: 60px;
        object-fit: contain;

        @media (max-width: 991px){
            width: 40px;
            height: 40px;
        }
    }
    p{
        font-size: 14px;
        margin-bottom: 0;

        @media (max-width: 500px){
            font-size: 13px;
        }
    }
    .link-blue{
        color: #4B9DFE;
        font-size: 14px;
        display: inline-flex;

        &:hover{
            text-decoration: none;
            color: #4b9cfe;
        }
        @media (max-width: 500px){
            font-size: 13px;
        }
        > img{
            width: 15px;
            margin-right: 8px;
        }
        span{

            @media (max-width: 500px){
                display: none;
            }
        }
    }
}
.file-separator {
    width: calc(100% - 50px);
    border-bottom: 1px solid #d6d6d6;
    margin-left: 25px;
}
.collapse.d-lg-block{

    @media (min-width: 992px) {
        display: block;
    }
}

.mob-course-content{

    @media (min-width: 992px) {
        min-height: 100%;
    }
    @media (max-width: 991px) {
        position: fixed;
        bottom: 0;
        width: 100%;
        max-height: 60vh;
        background-color: #ffffff;
        padding: 0 13px;
        overflow: auto;
    }
    .progress-block{
        position: sticky;
        top: 0px;
        background-color: #ffffff;
        padding: 8px 10px;
        z-index: 2;
    }
    .progress{
        border: solid 1px #EAEAEA;
        background-color: #ffffff;
        height: 15px;
        border-radius: 1.25rem;

        &.bg-success{
            background-color: #329B3C;
        }
        .progress-bar{
            font-size: 10px;
        }
    }
}
// video right block
.btn.btn-overview{
    color: #ffffff;
    background: #2E2E2E;
    border-radius: 4px;
    width: 100%;
    padding: 7px 18px;
    position: relative;
    text-align: left;

    .menu-arrow{
        border-color: #ffffff;
        top: 10px;
    }
    &[aria-expanded=true]{

        .menu-arrow{
            transform: rotate(45deg);
            transform-origin: 0 6px;
        }
    }
}
.video-info-block {
    width: 380px;
    margin-left: auto;
    flex: 0 0 380px;
    max-height: 100%;
    background: var(--color-bg);
    height: calc(100vh - 63px);
    border-left: solid 1px #d6d6d6;
    margin-top: 63px;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: right .3s;
    right: 0;
    overflow-y: scroll;

    @media (hover) {
        &::-webkit-scrollbar-thumb {
            background-color: #c8c8c8;
            border-radius: 16px;
        }
        &::-webkit-scrollbar {
            width: 0;
        }
    }

    @media (max-width: 991px) {
        width: 100%;
        flex: 0 0 auto;
        padding: 0;
        margin-left: 0;
        border: none;
        margin-top: auto;
        flex-grow: 1;
        max-height: inherit;
        height: auto;
        margin-bottom: 0;
        border-radius: 5px;
        position: inherit;
        order: 1;

        .px-4{
            padding: 13px 3px;
            margin-bottom: 0;
            position: sticky;
            top: 17px;
            z-index: 1;
            background-color: #ffffff;
        }
    }
    &.closed{
        right: -380px;
    }
}
.video-info-block-title{
    position: sticky;
    top: 31px;
    z-index: 2;
    background-color: #e4e4e4;
    max-height: 125px;
    padding: 10px 10px 10px;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 10px;

    @media (max-width: 991px) {
        font-size: 16px;
        padding: 10px 30px;
        max-height: inherit;
        overflow: visible;
        justify-content: center;
        line-height: 1.2;
        display: none;
    }
    @media (hover){
        &::-webkit-scrollbar{
            width: 5px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #c8c8c8;
            border-radius: 16px;
        }
    }

    > div{
        padding-right: 10px;
        font-weight: 500;
    }
    .btn svg{
        transform: rotate(180deg);
    }
}
.video-teacher-info {
    position: relative;
    transition: all 0.5s;
    width: 100%;
    padding: 0 10px 10px 10px!important;
    background-color: transparent!important;
    margin: 0!important;

    @media (max-width: 991px){
        padding: 0 25px 10px 25px!important;
    }
}
.menu-arrow{
    border-left: 1px solid #7d7d7d;
    border-top: 1px solid #7d7d7d;
    display: block;
    height: 10px;
    margin-left: auto;
    transform: rotate(
            223deg);
    transition: all 0.3s;
    width: 10px;
    position: absolute;
    right: 12px;
    top: 15px;
}
.accordion-button {
    background-color: var(--color-bg-btn);
    margin-bottom: 2px;
    border-radius: 3px;

    .btn {
        width: 100%;
        text-align: left;
        color: var(--color-text);
        padding: 0.5rem 10px;
        white-space: normal;
        text-transform: inherit;
        position: relative;
        border-radius: 0;
        border: none;
        min-height: 45px;

        &:focus {
            outline: none;
            box-shadow: none;
        }
        .menu-arrow{
            top: 50%;
            margin-top: -8px;
        }
        &[aria-expanded="true"]{
            background-color: #e1e1e1;

            .menu-arrow{
                transform: rotate(
                        45deg);
                transform-origin: 0 6px;
            }
            span {
                text-shadow: 0 0;
            }
        }
        .section-title {
            font-size: 15px;
            font-weight: 500;
            line-height: 1.3;
            display: inline-block;
            padding-right: 30px;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }
}
.play-ul {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 10px;

    li {
        position: relative;
        border-radius: 3px;
        background-color: #f9f9f9;
        margin-bottom: 2px;

        &:hover {
            background-color: var(--color-bg-hover);
        }
        @media (max-width: 991px){
            margin-bottom: 4px;
        }
        a {
            display: -webkit-box;
            display: flex;
            justify-content: space-between;
            color: var(--color-text);
            padding: 13px 10px;
            align-items: center;

            @media (max-width: 767px) {
                padding: 10px;
            }
            &:hover {
                text-decoration: none;
            }
            > img{
                height: 20px;
                width: 20px;
                margin-right: 10px;
                filter: contrast(0.1);
            }
            .play {
                height: 20px;
                width: 20px;
                font-size: 7px;
                background: var(--color-bg);
                border: 1px solid #b2b2b2;
                border-radius: 50%;
                text-align: center;
                padding: 3px 0 0 2px;
                -webkit-box-ordinal-group: 2;
                order: 1;
                margin-right: 10px;
                color: #686F7A;
                line-height: 14px;

                span {
                    vertical-align: 0;
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 4px 0 4px 6px;
                    border-color: transparent transparent transparent #b2b2b2;
                }
            }
            .play-title {
                -webkit-box-flex: 1;
                flex: 1;
                min-width: 1px;
                -webkit-box-ordinal-group: 3;
                order: 2;
                margin-right: 8px;
                line-height: 1.2;
                text-align: left;
                font-size: 13px;

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
            .play-time {
                -webkit-box-ordinal-group: 4;
                order: 3;
                opacity: 0.7;
                margin-right: 20px;
                line-height: 1.2;
                font-size: 9px;

                @media (max-width: 767px) {
                    font-size: 12px;
                }
            }
            .play-btn {
                height: 20px;
                width: 20px;
                font-size: 9px;
                border: 2px solid #cccccc;
                border-radius: 50%;
                text-align: center;
                padding: 3px 0 0 1px;
                order: 4;
                color: #FFF;
                background: #ffffff;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                &.play-btn-active {
                    background-color: #007791;
                    border-color: #007791;
                    span {
                        display: inline-block;

                        &:after {
                            content: '';
                            display: block;
                            width: 5px;
                            height: 9px;
                            border: solid #fff;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }
}
.play-ul .free-episode{
    margin-right: auto;
    position: absolute;
    right: 10px;
    font-size: 10px;
    top: 0;
    color: #16a8e3;
}
.play-ul .active {
    background-color: var(--color-bg-hover);

    &:hover {
        background-color: var(--color-bg-hover);
    }
}
.empty-title{
    padding: 10vh 10px;
    font-size: 26px
}
//end video



.text-black-btn{
    color: #323232;

    &:hover{
        text-decoration: underline;
        color: black;
    }
}
.student-feedback-container{
    padding: 60px 15px 60px;
    max-width: 1056px;
    margin: 0 auto;

    @media (max-width: 767px){
        padding: 40px 15px 40px;
    }
    h2{
        font-size: 20px;
        margin-bottom: 0;
    }
    .star-block{
        padding-left: 0;
    }

    .nav-pills .nav-link {
        background-color: #fff;
        color: #000;
        border: 1px solid #f5f5f5;
        font-weight: 500;
        border-radius: 10px;

        &.active {
            background-color: #f5f5f5;
            color: #000;
        }
    }

    .nav-pills {
        gap: 15px;
    }
    textarea.form-control{
        padding-right: 55px;
    }
}
.student-feedback-block{
    display: flex;
    align-items: flex-start;
    border-bottom: solid 1px #DDDFE5;
    padding-bottom: 8px;
    margin-bottom: 27px;
    padding-top: 9px;
    border-radius: 10px;

    > img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
    }
    .star-block{
        margin-bottom: 15px;

        .Stars{
            margin-left: 0;
        }
    }
    figcaption{
        flex-grow: 1;
        padding-left: 12px;

        h3{
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 0;

            + a{
                font-size: 12px;
            }
        }
        p{
            font-size: 15px;
        }
        .star-block span{
            color: #A7A7A7;
        }
    }
}

.star-count{
    color: #EFCE4A;
    padding-top: 2px;
}
:root {
    --star-size: 23px;
    --star-color: #ddd;
    --star-background: #EFCE4A;
}
.star-block{
    padding: 5px 10px 5px;
    display: flex;
    align-items: center;
    background-color: #ffffff;

    span{
        font-size: 10px;
        color: #7E7E7E;

        b{
            font-weight: 400;
        }
    }
    .star-count{
        display: none;
    }
    .Stars{
        margin: 0 3px;
        display: inline-flex;
        align-items: center;

        &:before{
            font-size: 19px;
            letter-spacing: 0;
        }
    }
}
.Stars {
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: var(--star-size);
    font-family: Times, serif;
    line-height: 1;

    &::before {
        content: '★★★★★';
        letter-spacing: 1px;
        background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}






.course-share-social-block{
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 20px;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 767px){
        bottom: inherit;
        top: 30px;
        left: initial;
        right: 10px;
        overflow: hidden;
    }
    a, button{
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px 5px 10px;
        border-radius: 0 6px 6px 0;
        border: 1px solid #EBEBEB;
        background: #ffffff;
        margin-bottom: 6px;
        white-space: nowrap;
        color: #000000;
        transition: padding .3s;

        @media (max-width: 767px){
            transform: translate(70px, 6px);
            transition: transform .3s;
            width: 36px;
            height: 36px;
            padding: 0;
            border-radius: 50%;
        }
        &:hover{
            text-decoration: none;

            @media (min-width: 768px){
                padding: 3px 10px 3px 20px;
            }

            span{
                text-indent: 0;
                margin-right: 20px;
            }
        }
        span{
            text-indent: -300px;
            text-transform: uppercase;
            font-size: 13px;
            transition: text-indent .15s;

            @media (max-width: 767px){
                display: none;
            }
        }
        img{
            height: 26px;
            width: 26px;
            flex: 0 0 26px;

            @media (max-width: 767px){
                width: 100%;
                height: 100%;
                flex: 0 0 100%;
            }
        }
    }

    &.social-share-open{

        a, button{
            transform: translate(0, 6px);
        }
    }
}
.empty-courses{
    font-size: 1.75rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    width: 100%;
    grid-column: span 3;
}

