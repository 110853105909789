
.home-h2{
    font-size: 30px;
    line-height: 1.35;
    margin-bottom: 12px;
    font-weight: 400;
    text-align: center;
    min-height: 32px;

    @media (max-width: 767px){
        font-size: 24px;
        margin-bottom: 6px;
    }
}
.home-static-container{

    > p{
        margin-bottom: 1.55rem;
        min-height: 22px;

        &:last-child{
            margin-bottom: 0;
        }
    }
}
.theme-3-header{
    font-size: clamp(12px, 1.17vw, 18px);
    max-width: 100%;
    margin: 0 auto;
    padding: 30px 3.3vw 30px;

    @media (max-width: 991px) {
        font-size: clamp(9px, 1.17vw, 18px);
    }
    @media (max-width: 767px){
        padding: 0;
        position: relative;
    }
}
.theme-3-header-text{
    width: 42.6%;
    padding: 12px 1.6em 12px 12px;
    margin-right: auto;

    @media (max-width: 991px){

        > .btn{
            font-size: 14px;
        }
    }
    @media (max-width: 767px){

        > .btn{
            font-size: 16px;
        }
    }
    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        z-index: 1;
        padding: 10vw 24px;
    }
    h1{
        font-weight: 400;
        margin-bottom: 0.55em;
        font-size: 2.2em;
        line-height: 1.35;
        min-height: 28px;

        @media (max-width: 767px){
            font-size: 22px;
            margin-bottom: 0.8em;
        }
    }
    > p{
        margin-bottom: 2.1em;
        font-size: 1.185em;
        line-height: 1.35;
        min-height: 22px;

        @media (max-width: 991px){
            font-size: 1.5em;
        }
        @media (max-width: 767px){
            font-size: 17px;
        }
    }
}
.theme-3-header-img{
    width: 46.81%;

    @media (max-width: 767px){
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: .3;
        height: 100%;
    }
    > img{
        width: 100%;
        object-fit: cover;

        @media (max-width: 767px){
            height: 100%;
        }
    }
}
.theme-3-section-2{
    padding: 48px 0 36px;

    @media (max-width: 767px){
        padding: 36px 0 24px;
    }
}
.theme-3-section-3{
    padding: 48px 0 36px;

    @media (max-width: 767px){
        padding: 36px 0 0;
    }
}
.theme-3-section-3-container{
    margin: 0 auto;
    max-width: 1300px;
    padding: 24px 0;
    position: relative;
    z-index: 1;
}
.theme-3-section-3-item{
    width: 33.33%;
    padding: 24px;
    position: relative;
    border-radius: 28px;

    @media (max-width: 1159px) {
        padding: 12px 10px 24px;
    }
    @media (max-width: 991px){
        text-align: center;
    }
    @media (max-width: 767px) {
        width: 100%;
        max-width: 400px;
        margin-bottom: 24px;
    }
    &:after{
        content: "";
        background: rgb(28,142,244);
        background: linear-gradient(0deg, rgba(28,142,244,1) 0%, rgba(121,227,237,1) 100%);
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        z-index: -1;
        transition: all .3s;
        border-radius: 28px;
    }
    &:hover{
        color: #ffffff;

        &:after{
            opacity: 1;
            height: 100%;
        }
    }
    > img{
        width: auto;
        max-width: 150px;
        height: 85px;
        border-radius: 50%;
        margin-bottom: 20px;
        object-fit: cover;
    }
    > h3{
        font-size: 19px;
        margin-bottom: .9em;
        min-height: 22px;

        @media (max-width: 1159px){
            font-size: 18px;
        }
    }
    > h4{
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 1.15em;
        min-height: 22px;
    }
    > p{
        font-size: 18px;
        line-height: 1.35;
        margin-bottom: 0;
        min-height: 22px;

        @media (max-width: 1159px){
            font-size: 14px;
        }
        @media (max-width: 767px){
            font-size: 18px;
        }
    }
}
.theme-3-section-4{
    padding: 48px 0 48px;

    @media (max-width: 767px){
        padding: 36px 0 36px;
    }
}
.theme-3-section-4-container{
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }
}
.theme-3-section-4-left{
    width: 55.37%;

    @media (max-width: 767px) {
        width: 100%;
        max-width: inherit;
    }
}
.theme-3-section-4-left-img{
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
}
.theme-3-section-4-right{
    width: 42%;
    margin-left: auto;
    padding: 12px 0 12px 12px;

    @media (max-width: 767px) {
        width: 100%;
        padding: 0;
    }
    > h2{
        font-size: 1.88em;
        font-weight: 400;
        margin-bottom: 1.52em;
        min-height: 22px;

        @media (max-width: 1159px){
            font-size: 1.7em;
            margin-bottom: 1.5em;
        }
        @media (max-width: 767px){
            text-align: center;
            font-size: 1.4em;
            margin-bottom: 1em;
        }
    }
    > p{
        font-size: 18px;
        letter-spacing: 0.03em;
        line-height: 1.4;
        margin-bottom: 1em;
        min-height: 22px;

        @media (max-width: 991px) {
            font-size: 16px;
        }
    }
}
.theme-3-section-5{
    padding: 48px 0 36px;

    @media (max-width: 767px){
        padding: 36px 0 24px;
    }
}
.theme-3-section-5-container{
    padding: 12px 0;
    margin: 0 -12px;
}
.theme-3-section-5-item{
    width: 25%;
    padding: 12px;

    @media (max-width: 1159px){
        width: 50%;
    }

    > img{
        width: 211px;
        height: 211px;
        border-radius: 50%;
        margin-bottom: 15px;
        object-fit: cover;

        @media (max-width: 767px){
            width: 140px;
            height: 140px;
        }
    }
    > h3{
        font-size: 20px;
        margin-bottom: .3em;
        min-height: 22px;

        @media (max-width: 767px){
            font-size: 17px;
        }
    }
    > p{
        font-size: 14px;
        line-height: 1.35;
        margin-bottom: 0;
        min-height: 22px;
    }
}
.theme-3-section-6{
    padding: 48px 0 24px;

    @media (max-width: 767px){
        padding: 36px 0 12px;
    }
}
.theme-3-section-6-container{
    margin: 0 -15px;
    padding: 24px 0;

    @media (max-width: 767px){
        margin: 0;
        padding-top: 24px;
    }
}
.theme-3-section-6-item{
    width: calc(50% - 30px);
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
    padding: 20px;
    border-radius: 60px 0 60px 0;
    text-align: center;
    margin: 40px 15px 40px;

    @media (max-width: 767px) {
        width: 100%;
        margin: 36px 0 36px;
        padding: 20px 15px;
    }
    &:nth-child(1){
        border-radius: 60px 0 60px 0;
    }
    &:nth-child(2){
        border-radius: 0 60px 0 60px;
    }
    &:nth-child(3){
        border-radius: 0 60px 0 60px;
    }
    &:nth-child(4){
        border-radius: 60px 0 60px 0;
    }
    &:hover{
        background-color: #F3F8FF;
    }
    > img{
        width: 79px;
        height: 79px;
        border-radius: 50%;
        margin-top: -60px;
        margin-bottom: 8px;
        box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
        border: solid 1px #ffffff;
    }
    > h3{
        font-size: 20px;
        min-height: 22px;
    }
    > h4{
        font-size: 14px;
        margin-bottom: 25px;
        min-height: 22px;
    }
    > p{
        font-size: 16px;
        min-height: 22px;

        @media (max-width: 500px){
            font-size: 14px;
        }
    }
}
